@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Pacifico&display=swap");

body {
  background-image: url("/Users/fady/Documents/NFT Projects/Dewy on Solana/dewy_on_solana/src/images/patterned_background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

.app {
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.app-header {
  background-color: rgba(40, 44, 52, 0.8); /* Adjusted for semi-transparency */
  padding: 20px;
  color: white;
}

.logo {
  width: 150px;
  animation: spin 20s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-content {
  color: #333;
  padding: 20px;
  font-size: 16px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.9),
    rgba(255, 215, 0, 0.8)
  );
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.image-container {
  margin-top: 20px;
}

.slider-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease, filter 0.5s ease;
  filter: brightness(0.8);
}

.slider-image:hover {
  transform: scale(1.1);
  filter: brightness(1);
}

.app-footer {
  background-color: rgba(40, 44, 52, 0.8); /* Adjusted for semi-transparency */
  padding: 20px;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

#root {
  height: 100%;
}

.link-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0;
}

a {
  display: block; /* Display each link as a block element */
  padding: 10px 20px;
  margin: 10px 0; /* Add margin to separate the links */
  color: black; /* Change text color to black */
  text-decoration: none;
  background-color: rgba(
    255,
    255,
    255,
    0.9
  ); /* Optional: semi-transparent background for readability */
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: "Pacifico", cursive; /* Use a fun and eye-catching font */
}

a:hover {
  background-color: #61dafb;
  color: white;
  text-decoration: none;
  transform: translateY(-5px);
}

.play-button {
  display: none;
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
  font-family: "Pacifico", cursive;
}

.play-button:hover {
  background-color: #61dafb;
  transform: translateY(-5px);
}
